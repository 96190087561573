import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub, faLinkedin, faTwitter, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import './styles.scss';

const Social = () => (
  <div className="social_icons">
    <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/AndrewMore')} />
    {/* <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://www.twitter.com')} />
    <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} /> */}
    <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/andrewdmore/')} />
  </div>
);

export default Social;
