import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Spinner from 'components/spinner';
import Hero from 'sections/hero';
import About from 'sections/about';
import Portfolio from 'sections/portfolio';
import Services from 'sections/services';
// import Testimonials from 'sections/testimonials';
// import Contact from 'sections/contact';

const HomePage = ({ data: { site } }) => (
  <div>
    <Helmet>
      <title>{site.meta.title}</title>
      <meta name="description" content={site.meta.description} />
      <meta name="image" content={`https://www.andrewdmore.com${site.meta.image}`} />
      <meta property="og:image" content={`https://www.andrewdmore.com${site.meta.image}`} />
    </Helmet>
    <Layout>
      <Hero id="home" />
      <About id="about" />
      <Services id="services" />
      <Portfolio id="portfolio" />
      {/* <Testimonials id="testimonials" /> */}
      {/* <Contact id="contact" /> */}
    </Layout>
    <Spinner duration={1000} />
  </div>
);

HomePage.propTypes = {
  data: PropTypes.object.
  isRequired,
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        image
      }
    }
  }
`;
