import React from 'react';
import './styles.scss';

const Video = ({ videoSrcURL, videoTitle, height, ...props }) => (
  <div className="video">
    <iframe
      width="100%"
      height={height}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
);
export default Video;
