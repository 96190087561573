import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './styles.scss';
import { Row, Col } from 'react-bootstrap';
import AnimationContainer from 'components/animation-container';
import BaffleText from 'components/baffle-text';
import Video from 'components/video';
import Tilt from 'react-tilt';
import ThemeContext from '../../context';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    const { items } = this.props;
    this.state = {
      category: null,
      col:
        items.length > 6
          ? 4
          : items.length > 4
            ? 3
            : items.length > 3
              ? 2
              : items.length > 1
                ? 2
                : 1,
      items,
      showPortfolio: false,
    };
    this.showPortfolio = this.showPortfolio.bind(this);
  }
  static contextType = ThemeContext;

  getItemCount(category) {
    const { items } = this.state;
    let total = 0;
    items.forEach((v) => {
      if (v.content.frontmatter.category === category || !category) total++;
    });
    return total;
  }

  items() {
    const { showPortfolio, category, col } = this.state;
    const { height } = this.context;
    if (showPortfolio || height === 'auto') {
      const { items } = this.state;
      return items.map((value, index) => {
        if (
          value.content.frontmatter.category === category
          || !category
        ) {
          if (value.content.frontmatter.image !== null) {
            return (
              <div
                className="portfolio_item"
                style={{
                  width:
                    height === 'auto'
                      ? '100%'
                      : col === 4
                        ? '25%'
                        : col === 3
                          ? '33.3%'
                          : col === 2
                            ? '50%'
                            : '100%',
                }}
                key={index}
              >
                <AnimationContainer delay={200} animation="fadeIn" key={index}>
                  <img
                    src={
                      value.content.frontmatter.image.childImageSharp.fluid.src
                    }
                    alt={value.content.frontmatter.title}
                    style={{
                      maxHeight: `${height
                        * (col >= 3
                          ? 0.35
                          : this.getItemCount(
                            value.content.frontmatter.category,
                          ) === 4
                            ? 0.36
                            : 1)}px`,
                      width: 'auto',
                    }}
                  />
                  <Tilt className="Tilt" options={{ scale: 1, max: 50 }}>
                    <div className="overlay">
                      <span className="title">
                        {value.content.frontmatter.title}
                      </span>
                    </div>
                  </Tilt>
                </AnimationContainer>
              </div>
            );
          } return (
            <div
              className="portfolio_item"
              style={{
                width:
            height === 'auto'
              ? '100%'
              : col === 4
                ? '25%'
                : col === 3
                  ? '33.3%'
                  : col === 2
                    ? '50%'
                    : '100%',
              }}
              key={index}
            >
              <AnimationContainer delay={200} animation="fadeIn" key={index}>
                <Video
                  videoSrcURL={value.content.frontmatter.videoSrcURL}
                  videoTitle={value.content.frontmatter.videoTitle}
                  height={height
                    * (col >= 4
                      ? 0.25
                      : col === 3
                        ? 0.35
                        : col === 2
                          ? 0.5
                          : 0.8)}
                //   style={{
                //     maxHeight: `${height
                // * (col >= 3
                //   ? 0.35
                //   : this.getItemCount(
                //     value.content.frontmatter.category,
                //   ) === 4
                //     ? 0.36
                //     : 1)}px`,
                //   }}
                />
                {/* <Tilt className="Tilt" options={{ scale: 1, max: 50 }}>
                  <div className="overlay">
                    <span className="title">
                      {value.content.frontmatter.title}
                    </span>
                  </div>
                </Tilt> */}
              </AnimationContainer>
            </div>
          );
        }
        return false;
      });
    }
  }

  showPortfolio() {
    this.setState({ showPortfolio: true });
  }

  changeCategory(category) {
    const { items } = this.props;
    this.setState({ items: [] });
    let total = 0;
    items.forEach((v) => {
      if (v.content.frontmatter.category === category || !category) total++;
    });
    const col = total > 6 ? 4 : total > 4 ? 3 : total > 3 ? 2 : total > 1 ? 2 : 1;

    this.setState({ category, col });
    setTimeout(() => {
      this.setState({ items });
    }, 50);
  }

  categories() {
    const { items } = this.props;
    const { category } = this.state;
    let categories = [];
    for (const v of items) {
      categories.push(v.content.frontmatter.category);
    }
    categories = [...new Set(categories)];
    return categories.map((value, index) => (
      <button
        type="button"
        className="portfolio_category"
        onClick={() => this.changeCategory(value)}
        key={index}
      >
        <span className={`${category === value ? 'active' : ''}`}>
          {value}
        </span>
      </button>
    ));
  }

  render() {
    const { category } = this.state;
    const { id } = this.props;
    const { height } = this.context;
    return (
      <section
        id={`${id}`}
        className="portfolio"
        style={{ height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Portfolio"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.showPortfolio}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={10} className="recent-works">
            <div className="portfolio_selector">
              <button
                type="button"
                className="portfolio_category"
                onClick={() => this.changeCategory(null)}
              >
                <span className={`${!category ? 'active' : ''}`}>
                  All
                </span>
              </button>
              {this.categories()}
            </div>

            <div className="content">
              <div
                className="portfolio_container"
                style={{
                  maxHeight:
                    height !== 'auto'
                      ? height * 0.8
                      : 'inherit',
                }}
              >
                {this.items()}
              </div>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
          query {
            items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(portfolio)(?!\\-site)/"}}, sort: {fields: [frontmatter___id], order: ASC},
            # The layout is built for 6 portfolio items #
            limit: 8) {
              edges {
                content: node {
                  html
                  frontmatter {
                    id
                    title
                    category
                    image {
                      childImageSharp {
                        fluid(maxWidth: 2000, maxHeight: 2000) {
                          src
                        }
                      }
                    }
                    videoSrcURL
                    videoTitle
                  }
                }
              }
            }
          }
        `}
    render={({ items }) => <Portfolio items={items.edges} {...props} />}
  />
);
