import React from 'react';
import './styles.scss';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';
import Glitch from '../../components/glitch-effect';
import ThemeContext from '../../context';

class Hero extends React.Component {
  static contextType = ThemeContext;
  icons() {
    const { icons: { edges } } = this.props;
    return edges.map((value, index) => (
      <img
        src={value.node.childImageSharp.fluid.src}
        className={`animated fadeIn move-${
          Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
        } float-image`}
        style={{
          left: `${index * 10}%`,
          bottom: `${Math.random()
              * (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10))
              + +(index % 2 === 0 ? 70 : 10)}%`,
        }}
        alt="shape"
        key={index}
      />
    ));
  }

  render() {
    const { id, mainImg: { childImageSharp } } = this.props;
    const { height } = this.context;
    return (
      <section
        id={`${id}`}
        className="hero"
        style={{ height }}
      >
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <Glitch text="Andrew More" />
              <Typewriter
                options={{
                  strings: [
                    'Frontend Web Developer',
                    'Full Stack Engineer',
                    'Project Manager',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              {/* <button type="button" className="hover-button">
                <span>Download CV</span>
              </button> */}
            </div>
            {this.icons()}
          </Col>
          <Col md={6} className="img">
            <img
              src={childImageSharp.fluid.src}
              alt="person"
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "hero-icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "Andrew.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
);
