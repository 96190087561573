import React from 'react';
import Particles from 'react-particles-js';
import Progress from 'components/progress';
import Social from '../../components/social';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub, faLinkedin, faTwitter, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import ThemeContext from '../../context';
import './styles.scss';

class Hero extends React.Component {
  //Public static fields are useful when you want a field to exist only once per class, not on every class instance you create. This is useful for caches, fixed-configuration, or any other data you don't need to be replicated across instances.
  static contextType = ThemeContext;

  particles() {
    return (
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: false,
                value_area: 5000,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.5,
            },
            size: {
              value: 1,
            },
          },
          retina_detect: true,
        }}
      />
    );
  }

  render() {
    const { id } = this.props;
    const { height } = this.context;
    return (
      <section id={`${id}`} className="about" style={{ height }}>
        {this.particles()}
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>About Me</h4>
              </div>
              <h3>I'm a Full Stack web developer</h3>
              <div className="separator" />
              <p>I am a full stack engineer and project manager with deep understanding of modern web and mobile technologies, currently seeking my next professional opportunity in the Los Angeles area. I am driven by creative problem solving, continuous learning, and teamwork, and am passionate about building applications that improve workflows and create efficiencies for the end user. As a former Marine, with 15+ years of leadership and project management experience, I have a strong foundation in communication, collaboration, relationship building, and am experienced in working with technical teams and applying agile methodologies to software development.</p>
              <Social />
            </div>
          </Col>
          <Col md={6} className="skills">
            <div className="line-text">
              <h4>My Skills</h4>
            </div>
            <div className="skills-container">
              <Progress name="HTML" value={90} delay={1100} />
              <Progress name="CSS" value={90} delay={1100} />
              <Progress name="React" value={85} delay={1100} />
              <Progress name="React Native" value={80} delay={1100} />
              <Progress name="NodeJS" value={85} delay={1100} />
              <Progress name="SQL" value={90} delay={1100} />
              <Progress name="NoSQL" value={85} delay={1100} />
              {/* <Progress name="Photoshop" value={85} delay={1100} /> */}
              {/* <Progress name="UX Design" value={80} delay={1100} /> */}
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default Hero;
