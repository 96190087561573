import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';
import BaffleText from 'components/baffle-text';
import AnimationContainer from 'components/animation-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faReact,
  faSass,
  faCss3Alt,
  faHtml5,
  faNode,
  faWordpress,
  faAws,
} from '@fortawesome/free-brands-svg-icons';
import {
  faPencilRuler,
  faRobot,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
} from '@fortawesome/free-solid-svg-icons';
import Counter from 'components/counter';
import ThemeContext from '../../context';
import './styles.scss';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.show = this.show.bind(this);
  }

  static contextType = ThemeContext;

  show() {
    this.setState({ show: true });
  }

  services() {
    const { show } = this.state;
    const { height } = this.context;
    const { data } = this.props;
    if (show || height === 'auto') {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={200} animation="fadeInLeft fast">
              <h4>Front-end Development</h4>
              <Row>
                <div className="icon">
                  <FontAwesomeIcon icon={faHtml5} />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon={faCss3Alt} />
                </div>
                <div className="icon">
                  <img src={data.jQuery.nodes[0].childImageSharp.fluid.src} />
                </div>
              </Row>
              <Row>
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <div className="icon">
                  <img src={data.GraphQL.nodes[0].childImageSharp.fluid.src} />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon={faSass} />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInDown fast">
              <h4>Backend Development</h4>
              <Row>
                <div className="icon">
                  <FontAwesomeIcon icon={faNode} />
                </div>
                <div className="icon">
                  <img src={data.mongoDB.nodes[0].childImageSharp.fluid.src} />
                </div>
              </Row>
              <Row>
                <div className="icon">
                  <img src={data.sequelize.nodes[0].childImageSharp.fluid.src} />
                </div>
                <div className="icon">
                  <img src={data.postgreSQL.nodes[0].childImageSharp.fluid.src} />
                </div>
                <div className="icon">
                  <img src={data.mySQL.nodes[0].childImageSharp.fluid.src} />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={600} animation="fadeInRight fast">
              <h4>Mobile App Development</h4>
              <Row style={{margin: '30px 0'}}>
                <div className="icon">
                  <img src={data.expo.nodes[0].childImageSharp.fluid.src} />
                </div>
                <div className="icon">
                  <FontAwesomeIcon icon={faReact} />
                </div>
                <div className="icon">
                  <img src={data.firebase.nodes[0].childImageSharp.fluid.src} />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={800} animation="fadeInLeft fast">
              <h4>UI/UX Design</h4>
              <Row style={{margin: '30px 0'}}>
                <div className="icon">
                  <FontAwesomeIcon icon={faPencilRuler} className="solid" />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={1000} animation="fadeInUp fast">
              <h4>Wordpress Development</h4>
              <Row style={{margin: '30px 0'}}>
                <div className="icon">
                  <FontAwesomeIcon icon={faWordpress} className="solid" />
                </div>
                <div className="icon">
                  <img src={data.wooCommerce.nodes[0].childImageSharp.fluid.src} />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={1200} animation="fadeInRight fast">
              <h4>Project Management</h4>
              <Row style={{margin: '30px 0'}}>
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} className="solid" />
                </div>
              </Row>
            </AnimationContainer>
          </Col>
        </Row>
      );
    }
  }

  counters() {
    const { show } = this.state;
    const { height } = this.context;
    if (show || height === 'auto') {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSmileBeam}
                value={20}
                text="Happy Clients"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faPizzaSlice}
                value={10000}
                text="Pizzas Ordered"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faQuoteRight}
                value={10}
                text="Reviews"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={50000}
                text="Lines of Code"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      );
    }
  }

  render() {
    const { id } = this.props;
    const { height } = this.context;
    return (
      <section
        id={`${id}`}
        className="services"
        style={{ height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              height !== 'auto'
                ? height * 0.8
                : 'inherit',
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Services</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="What I Do"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    height !== 'auto'
                      ? height * 0.6
                      : 'inherit',
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
    query {
      expo: allFile(filter: {relativePath: {eq: "icons/expo.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      mongoDB: allFile(filter: {relativePath: {eq: "icons/mongoDB.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      jQuery: allFile(filter: {relativePath: {eq: "icons/jQuery.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      GraphQL: allFile(filter: {relativePath: {eq: "icons/GraphQL.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      mySQL: allFile(filter: {relativePath: {eq: "icons/mySQL.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      postgreSQL: allFile(filter: {relativePath: {eq: "icons/postgreSQL.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      sequelize: allFile(filter: {relativePath: {eq: "icons/sequelize.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      wooCommerce: allFile(filter: {relativePath: {eq: "icons/wooCommerce.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
      firebase: allFile(filter: {relativePath: {eq: "icons/firebase.png"}}) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 100) {
              src
            }
          }
        }
      }
    }
    `}
    render={(data) => <Services data={data} {...props} />}
  />
);
